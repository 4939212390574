




















































































import * as R from 'ramda';
import { defineComponent, ref, computed, watch } from '@vue/composition-api';
import { OrbitSpinner } from 'epic-spinners';
import { FormModal } from '@/app/components';
import { Status } from '../constants';
import { LockClosedIcon } from '@vue-hero-icons/outline';
import EditModel from './EditModel.vue';
import { ModelsAPI } from '../api';
import { UpdateModelConcept } from '../types';
import { S } from '@/app/utilities';

export default defineComponent({
    name: 'ModelDetails',
    model: {
        prop: 'model',
        event: 'update-model',
    },
    props: {
        model: {
            type: Object,
            required: true,
        },
        createModel: {
            type: Boolean,
            default: false,
        },
        readOnly: {
            type: Boolean,
            default: false,
        },
        changesToBeSaved: {
            type: Object,
            default: () => {},
        },
        savedChanges: {
            type: Object,
            default: () => {},
        },
        unblockEdit: {
            type: Boolean,
            default: false,
        },
        discardChanges: {
            type: Boolean,
            default: false,
        },
    },
    components: { FormModal, EditModel, OrbitSpinner, LockClosedIcon },
    setup(props, { root, emit }) {
        const showDetails = ref(false);
        const editModel = ref(false);
        const loading = ref(false);
        const updateModelError = ref(null);

        const cloneModel = () => {
            const clone = R.clone(props.model);
            clone.children = [];
            return clone;
        };

        const clonedModel = ref(cloneModel());

        const isCurrentModelDraft = computed(() => props.model.status === Status.Draft);
        const modelLocked = computed(
            () => props.model.status === Status.Stable && props.model.majorVersion < props.model.domainMajorVersion, // stable model, but a higher major version which is under review exists
        );

        const saveModel = (model: any) => {
            loading.value = true;
            ModelsAPI.updateConcept(clonedModel.value.id, clonedModel.value as UpdateModelConcept)
                .then(() => {
                    (root as any).$toastr.s(
                        `Model '${S.sanitizeHtml(props.model.name)}' successfully updated`,
                        'Updated',
                    );
                    editModel.value = false;
                    emit('update-model', model);
                })
                .catch((e) => {
                    updateModelError.value = e.response.data.message;
                })
                .finally(() => {
                    loading.value = false;
                });
        };

        watch(
            () => props.discardChanges,
            (discard: boolean) => {
                if (discard) {
                    editModel.value = false;
                    emit('closeModal');
                    clonedModel.value = cloneModel();
                }
            },
        );

        const cancelEditModel = () => {
            editModel.value = false;
            clonedModel.value = cloneModel();
            updateModelError.value = null;
            emit('closeModal');
        };

        const saveOrView = (model: any) => {
            updateModelError.value = null;
            if (props.readOnly) {
                cancelEditModel();
            } else {
                saveModel(model);
            }
        };

        const change = (field: any, value: any) => {
            updateModelError.value = null;
            emit('change', field, value, true);
        };

        const allowSave = computed(
            () => props.changesToBeSaved.unsaved && Object.keys(props.changesToBeSaved.unsaved).length > 0,
        );

        const cancelChanges = () => {
            updateModelError.value = null;
            if (allowSave.value) {
                emit('discardModelChanges');
            } else {
                cancelEditModel();
            }
        };

        const openEditModel = () => {
            editModel.value = true;
            emit('editingModel');
        };

        return {
            cancelEditModel,
            change,
            clonedModel,
            editModel,
            emit,
            isCurrentModelDraft,
            modelLocked,
            loading,
            saveModel,
            saveOrView,
            showDetails,
            Status,
            updateModelError,
            cancelChanges,
            allowSave,
            openEditModel,
        };
    },
});

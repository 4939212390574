var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('portal',{attrs:{"to":"modals"}},[(_vm.editModel || _vm.unblockEdit)?_c('FormModal',{attrs:{"title":_vm.readOnly ? 'Model Details' : 'Update Model',"saveButtonText":_vm.readOnly ? 'OK' : 'Update',"width":"xl:w-1/2 sm:w-2/3 xs:w-full","loading":_vm.loading,"allowSave":_vm.allowSave},on:{"cancel":_vm.cancelChanges,"save":_vm.saveOrView},scopedSlots:_vm._u([{key:"loader",fn:function(){return [_c('OrbitSpinner',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],attrs:{"animation-duration":1000,"size":32,"color":"#5C69D1"}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],staticClass:"ml-3 text-neutral-600"},[_vm._v("Saving model details...")])]},proxy:true}],null,false,1282705808)},[_c('EditModel',{attrs:{"createModel":_vm.isCurrentModelDraft,"read-only":_vm.readOnly,"error":_vm.updateModelError,"changesToBeSaved":_vm.changesToBeSaved,"savedChanges":_vm.savedChanges},on:{"change":_vm.change},model:{value:(_vm.clonedModel),callback:function ($$v) {_vm.clonedModel=$$v},expression:"clonedModel"}}),_c('hr',{staticClass:"mt-4"})],1):_vm._e()],1),_c('div',{staticClass:"flex flex-row w-full space-x-4 cursor-pointer",on:{"mouseenter":function($event){_vm.showDetails = true},"mouseleave":function($event){_vm.showDetails = false},"click":function($event){_vm.changesToBeSaved && Object.keys(_vm.changesToBeSaved.unsaved).length
                ? _vm.emit('blockEditIfChanges')
                : _vm.openEditModel()}}},[_c('div',{staticClass:"flex flex-row space-x-2"},[_c('div',{staticClass:"flex items-center capitalize",class:{
                    ' text-neutral-600': _vm.model.status === _vm.Status.Deprecated,
                    'text-neutral-800': _vm.model.status !== _vm.Status.Deprecated,
                }},[_c('span',{staticClass:"mr-1"},[_vm._v(" "+_vm._s(_vm.model.name)+" ")])]),_c('div',{staticClass:"flex items-center text-lg"},[_c('span',{staticClass:"flex px-2 py-1 rounded",class:{
                        'text-blue-700 bg-blue-200': _vm.model.status === _vm.Status.Draft,
                        'text-green-700 bg-green-200': _vm.model.status === _vm.Status.Stable && !_vm.modelLocked,
                        'text-neutral-500 bg-neutral-200': _vm.model.status === _vm.Status.Deprecated,
                        'text-orange-700 bg-orange-200 ': _vm.model.status === _vm.Status.UnderRevision,
                        'text-red-700 bg-red-200': _vm.modelLocked,
                    }},[(_vm.modelLocked)?_c('div',{staticClass:"flex flex-row items-center text-center"},[_c('LockClosedIcon',{staticClass:"w-5 h-5"}),_c('span',{staticClass:"w-full text-center"},[_vm._v(_vm._s(_vm.model.version)+" - Stable")])],1):[_vm._v(_vm._s(_vm.model.version)+" - "+_vm._s(_vm.model.status === _vm.Status.UnderRevision ? 'In Review' : _vm.model.status))]],2)])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showDetails),expression:"showDetails"}],staticClass:"flex items-center text-base truncate text-neutral-500",attrs:{"title":_vm.model.description}},[_vm._v(" "+_vm._s(_vm.model.description)+" ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex items-center justify-between p-2 rounded cursor-pointer",class:{
        'font-bold cursor-default': _vm.selected,
        'hover:bg-neutral-200': !_vm.selected,
        'italic text-secondary-700': _vm.isSuggestion,
    },on:{"click":function($event){!_vm.selected ? _vm.emit('click', _vm.concept) : null}}},[_c('div',{staticClass:"flex items-center justify-between w-full"},[_c('div',{staticClass:"flex items-center w-64"},[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:(_vm.conceptTooltip),expression:"conceptTooltip",modifiers:{"bottom":true}}],staticClass:"mr-1 truncate",class:{
                    'text-red-600': _vm.deprecated,
                    'text-primary-600': _vm.newConcept,
                }},[_vm._v(" "+_vm._s(_vm.concept.name)+" ")])]),_c('div',{staticClass:"flex items-center"},[(_vm.unsavedChange !== null)?_c('ChangeIndication',{staticClass:"mr-2",attrs:{"unsaved":_vm.unsavedChange}}):_vm._e(),(_vm.concept.referenceConceptId)?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.concept.referenceConceptName),expression:"concept.referenceConceptName"}],staticClass:"flex items-center justify-center w-6 h-6 px-1 text-sm rounded-full bg-primary-100 hover:bg-primary-200 hover:cursor-pointer",class:{
                    'text-neutral-700': !_vm.selected,
                    'bg-red-200 text-red-600 hover:bg-red-300 ': _vm.deprecated,
                    'text-primary-600 bg-primary-200': _vm.newConcept,
                },on:{"click":function($event){return _vm.$emit('navigate-to-referenced-concept', {
                        id: _vm.concept.referenceConceptParentId,
                        referencedHLConceptId: _vm.concept.referenceConceptId,
                    })}}},[_c('LinkIcon',{staticClass:"w-5 h-5"})],1):(!_vm.isSuggestion)?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(("" + (_vm.proposedMode ? 'Number of suggested fields' : 'Number of fields'))),expression:"`${proposedMode ? 'Number of suggested fields' : 'Number of fields'}`"}],staticClass:"flex items-center justify-center w-6 h-6 px-2 text-sm rounded-full bg-primary-100",class:{
                    'text-neutral-700': !_vm.selected,
                    'bg-red-200 text-red-600': _vm.deprecated,
                    'text-primary-600 bg-primary-200': _vm.newConcept,
                }},[_vm._v(" "+_vm._s(_vm.proposedMode ? _vm.concept.noOfSuggestedFields : _vm.concept.noOfFields)+" ")]):_vm._e()],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }